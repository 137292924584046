import React, { useEffect, useMemo, useState } from 'react'
import { Table, Spin, Button } from 'antd'
import { getCouponStatistic } from '@/apis/mealCoupon'
import { formatAmount, toExportExcel } from '@/utils'
import './index.less'

const CouponRecord = ({ history }) => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [info, setInfo] = useState({
        give_coupon: {
            new_amount: 0,
            recharge_amount: 0,
            store_amount: 0,
            total_amount: 0
        },
        use_coupon: {
            new_amount: 0,
            recharge_amount: 0,
            store_amount: 0,
            total_amount: 0
        },
        shengyu_coupon: {
            new_amount: 0,
            recharge_amount: 0,
            store_amount: 0,
            total_amount: 0
        },
    })
    const [isMainStore, setIsMainStore] = useState(1)
    const columns = useMemo(() => {
        return isMainStore ? [
            {
                title: '年月',
                dataIndex: 'month'
            },
            {
                title: '商家账号',
                dataIndex: 'store_mobile'
            },
            {
                title: '商家名称',
                dataIndex: 'short_name'
            },
            {
                title: '发放餐券金额',
                dataIndex: 'issue_vouchers_total_amount',
                render: (text, record, index) => `¥${text}`
            },
            {
                title: '发放餐券订单数',
                dataIndex: 'issue_vouchers_order_num'
            },
            {
                title: '月营业额',
                dataIndex: 'amount',
                render: (text, record, index) => `¥${text}`
            },
            {
                title: '操作',
                dataIndex: 'oper',
                render: (text, record, index) => {
                    return (<span className="oper-btn" onClick={() => {
                        history.push(`/couponRecord/detail?store_id=${record.store_id}`);
                    }}>查看明细</span>)
                }
            }
        ] : [
            {
                title: '年月',
                dataIndex: 'month'
            },
            {
                title: '发放餐券金额',
                dataIndex: 'issue_vouchers_total_amount',
                render: (text, record, index) => `¥${text}`
            },
            {
                title: '发放餐券订单数',
                dataIndex: 'issue_vouchers_order_num'
            },
            {
                title: '月营业额',
                dataIndex: 'amount',
                render: (text, record, index) => `¥${text}`
            },
            {
                title: '操作',
                dataIndex: 'oper',
                render: (text, record, index) => {
                    return (<span className="oper-btn" onClick={() => {
                        history.push(`/couponRecord/detail?store_id=${record.store_id}`);
                    }}>查看明细</span>)
                }
            }
        ]
    }, [isMainStore])

    useEffect(() => {
        getData()
    }, [])

    const getData = async() => {
        try {
            setLoading(true)
            const { data } = await getCouponStatistic().finally(() => {
                setLoading(false)
            })
            setIsMainStore(data.is_main_store)
            setList(data.list)
            setInfo({
                give_coupon: data.give_coupon,
                use_coupon: data.use_coupon,
                shengyu_coupon: data.shengyu_coupon
            })
        } catch(e) {
            console.log(e)
        }
    }

    // 导出Excel
    const onExportExcel = () => {
        if (loading) return;
        const c = columns.filter(t => t.dataIndex != 'oper')
        const header = c.map(t => t.title)
        const filter = c.map(t => t.dataIndex)
        const data = list.map(t => {
            t.amount = `¥${t.amount}`
            t.issue_vouchers_total_amount = `¥${t.issue_vouchers_total_amount}`
            return t
        })
        toExportExcel({
            fileName: `发放餐券记录${Date.now()}`,
            data,
            header,
            filter
        })
    }

    return (
        <Spin spinning={loading}>
            <div className="flex-gap">
                <div className="mealCoupon-card">
                    <div className="card-title">发放餐券</div>
                    <div className="card-number">{formatAmount(info.give_coupon.total_amount)}</div>
                    <div className="card-text flex">
                        <span>店铺{formatAmount(info.give_coupon.store_amount)}元</span>
                        <span>充值{formatAmount(info.give_coupon.recharge_amount)}元</span>
                        <span>新人券{formatAmount(info.give_coupon.new_amount)}元</span>
                    </div>
                </div>
                <div className="mealCoupon-card">
                    <div className="card-title">使用餐券</div>
                    <div className="card-number">{formatAmount(info.use_coupon.total_amount)}</div>
                    <div className="card-text flex">
                        <span>店铺{formatAmount(info.use_coupon.store_amount)}元</span>
                        <span>充值{formatAmount(info.use_coupon.recharge_amount)}元</span>
                        <span>新人券{formatAmount(info.use_coupon.new_amount)}元</span>
                    </div>
                </div>
                <div className="mealCoupon-card">
                    <div className="card-title">剩余餐券</div>
                    <div className="card-number">{formatAmount(info.shengyu_coupon.total_amount)}</div>
                    <div className="card-text flex">
                        <span>店铺{formatAmount(info.shengyu_coupon.store_amount)}元</span>
                        <span>充值{formatAmount(info.shengyu_coupon.recharge_amount)}元</span>
                        <span>新人券{formatAmount(info.shengyu_coupon.new_amount)}元</span>
                    </div>
                </div>
            </div>
            <div className="mealCoupon-title">
                <div className="title">{isMainStore ? '本店和子店铺发放的餐券记录列表' : '本店发放的餐券记录列表'}</div>
                <Button type="primary" style={{ marginLeft: 'auto' }} onClick={onExportExcel}>导出Excel</Button>
            </div>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={list}
                bordered
                pagination={false}
            />
        </Spin>
    )
}

export default CouponRecord