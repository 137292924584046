import qs from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Empty, Modal, Row, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import RefundModal from './components/refundModal'
import RemarkModal from './components/remarkModal'
import ZCountDown from '@/components/zCountDown/zCountDown'
import { ORDER_STATUS, REMARK_TYPE_STATUS, diningOut, getOrderDetail, isOrderCanceled, isOrderInProgress } from '@/apis/order'
import { timestampToString, copy } from '@/utils'
import './TakeoutDelivery.less'

const TakeoutSelfHelpDetail = ({ history, location }) => {
    const { id } = qs.parse(location.search)
    const [info, setInfo] = useState({})
    const refundModalRef = useRef(null)
    const remarkModalRef = useRef(null)

    useEffect(() => {
        getData()
    }, [])

    const getData = async() => {
        try {
            const { data } = await getOrderDetail(id)
            let isRefund = false
            if (data.pay_at) {
                const pay_at = +new Date(data.pay_at.replace(/-/g, '/'))
                const t = (48*60*60*1000) - (Date.now() - pay_at)
                if (isOrderInProgress(data.status) || (data.status === 1 && t >= 0)) {
                    isRefund = true
                }
            }
            data.isRefund = isRefund
            if (data.status === 0) {
                data.expiry_time = 900 - (Date.now() / 1000 - data.order_created)
            }
            if (data.order_refund && data.order_refund.status === 2) {
                data.refund_amount = data.order_refund.refund_amount
            }
            setInfo(data)
        } catch(e) {
            console.log(e)
        }
    }

    // 申请退款
    const onRefund = () => {
        const pay_at = +new Date(info.pay_at.replace(/-/g, '/'))
        const t = (48*60*60*1000) - (Date.now() - pay_at)
        if (info.status === 1 && t < 0) {
            message.error('已超过48小时，不可申请退款')
            return
        }
        refundModalRef.current && refundModalRef.current.show({
            order_id: id,
            coupon: +info.coupon,
            freight: +info.freight,
            package_price: +info.package_price,
            package_status: info.package_status,
            package_type: info.package_type,
            order_type: info.order_type,
            pack_type: info.pack_type,
            list: info.order_items
        })
    }

    // 添加备注
    const addRemark = () => {
        remarkModalRef.current && remarkModalRef.current.show({
            order_id: id,
            content: info.notes?.content || ''
        })
    }


    // 出餐
    const onDiningOut = () => {
        Modal.confirm({
            title: '是否确认出餐？',
            content: '请确认菜品无误后再出餐',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            onOk: async () => {
                try {
                    const res = await diningOut(id)
                    if (res.errors.code === 1) {
                        message.success('出餐成功')
                        getData()
                    }
                } catch(e) {
                    console.log(e)
                }
            }
        })
    }

    return (
        <div>
            <div className="takeout-container">
                <div className="card order-card">
                    <div className="item-header">
                        <div className="item-label flex align-center">
                            订单编号：<div className="order_no">{info.order_no || '-'}</div>
                            {!!info.order_no && <div className="icon-copy" onClick={() => copy(info.order_no)}></div>}
                        </div>
                        <div className="item-label flex align-center">下单时间：<span>{info.created_at ? timestampToString(info.created_at * 1000, 3) : '-'}</span></div>
                        <Button className="add-remark-btn" size="small" onClick={addRemark}>{info.notes ? '修改备注' : '添加备注'}</Button>
                    </div>
                    {!!info.notes && <div className="item-inner">
                        <div className="flex">
                            <div className="flex-shrink-0">商家备注：</div>
                            <div>{info.notes.content}</div>
                        </div>
                    </div>}
                </div>
                {info.status != undefined && <div className="card process-card flex">
                    {info.status == 0 && <div className="item-media">
                        <div className="item-title">{ORDER_STATUS[info.status]}</div>
                        <div className="item-subtl">等待买家付款</div>
                        <div className="item-subtl">
                            <ZCountDown value={info.expiry_time} format="剩余mm分ss秒" onTimeEnd={getData} />
                        </div>
                    </div>}
                    {info.status == 1 && <div className="item-media">
                        <div className="item-title">{ORDER_STATUS[info.status]}</div>
                        <div className="item-subtl">顾客已取走，订单完成</div>
                    </div>}
                    {info.status == 2 && <div className="item-media">
                        <div className="item-title">制作中</div>
                        <div className="item-subtl">正在准备餐品</div>
                    </div>}
                    {info.status == 3 && <div className="item-media">
                        <div className="item-title">{ORDER_STATUS[info.status]}</div>
                        <div className="item-subtl">顾客未在规定时间内完成支付，订单已自动取消</div>
                    </div>}
                    {info.status == 4 && <div className="item-media">
                        <div className="item-title">待核销</div>
                        <div className="item-subtl">餐品已备好，等待顾客到店自取</div>
                    </div>}
                    {info.status == 5 && <div className="item-media">
                        <div className="item-title">{ORDER_STATUS[info.status]}</div>
                        <div className="item-subtl">{REMARK_TYPE_STATUS[info.order_refund?.remark_type] || '买家发起退款'}</div>
                        <div className="item-subtl">申请退款原因:{info.order_refund?.remark_type_text}</div>
                    </div>}
                    {[6, 7, 71, 72].includes(info.status) && <div className="item-media">
                        <div className="item-title">已退款</div>
                        <div className="item-subtl">{info.status == 6 ? (REMARK_TYPE_STATUS[info.order_refund?.remark_type] || '买家发起退款') : ORDER_STATUS[info.status]}</div>
                        <div className="item-subtl">已退款<span style={{ marginLeft: '3px', color: '#F04A3F' }}>¥{info.refund_amount}</span></div>
                    </div>}
                </div>}
                <div className="card info-card">
                    <Row>
                        <Col span={8}>
                            <div className="item-title flex align-center">收货人信息{!!info.address && <span className="icon-copy" onClick={() => copy(info.address.mobile)}></span>}</div>
                            <div className="item-cell">
                                <div className="item-cell__label">预留电话：</div>
                                <div className="item-cell__inner">{info.address?.mobile || '-'}</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="item-title">付款信息</div>
                            <div className="item-cell">
                                <div className="item-cell__label">支付方式：</div>
                                <div className="item-cell__inner">{({ 0: '余额支付', 1: '微信支付' })[info.pay_type] || '-'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">付款时间：</div>
                                <div className="item-cell__inner">{info.pay_at || '-'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">商品总额：</div>
                                <div className="item-cell__inner">¥{info.product_amount || '0.00'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">实际到账：</div>
                                <div className="item-cell__inner">¥{info.status != undefined && !(info.status == 0 || isOrderCanceled(info.status))
                                    ? (info.actual_amount - info.refund_amount).toFixed(2)
                                    : '0.00'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">返红包：</div>
                                <div className="item-cell__inner">¥{info.rebate_hongbao || '0.00'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">发放餐券：</div>
                                <div className="item-cell__inner">¥{info.rebate_amount || '0.00'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">使用红包：</div>
                                <div className="item-cell__inner">¥{info.coupon || '0.00'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">抵扣餐券：</div>
                                <div className="item-cell__inner">¥{info.deduction_amount || '0.00'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">打包费：</div>
                                <div className="item-cell__inner">¥{info.package_price || '0.00'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">退款金额：</div>
                                <div className="item-cell__inner">¥{info.refund_amount || '0.00'}</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="item-title">买家信息</div>
                            <div className="item-cell">
                                <div className="item-cell__label">买家：</div>
                                <div className="item-cell__inner">{info.user?.username || '-'}</div>
                            </div>
                            <div className="item-cell">
                                <div className="item-cell__label">买家手机号：</div>
                                <div className="item-cell__inner">{info.user?.mobile || '-'}</div>
                            </div>
                            <div className="item-remark flex">
                                <div className="flex-shrink-0">买家留言：</div>
                                <div style={{ paddingLeft: '6px' }}>{info.remake || '-'}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="order-list">
                    <table className="item-inner">
                        <colgroup>
                            <col style={{ width: '20%' }} />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col />
                            <col style={{ width: '125px' }} />
                            <col style={{ width: '135px' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>商品信息</th>
                                <th>单价</th>
                                <th>数量</th>
                                <th>供应商</th>
                                <th>商品金额</th>
                                <th>售后状态</th>
                                <th>实收款</th>
                                <th>预计取餐时间</th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!info.order_items ? info.order_items.map((good, goodIdx) => (<tr key={good.id}>
                                <td>
                                    <div className="item-goods flex">
                                        <div className="item-goods__media flex-shrink-0">
                                            <img className="item-goods__img" src={good.product_cover} alt="" />
                                        </div>
                                        <div className="item-goods__inner flex-1">
                                            <div className="item-goods__title bold ell1">{good.product_name}</div>
                                            <div className="item-goods__subtl">{good.product_spec_val}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="bold">¥{good.product_price}</div>
                                </td>
                                <td>
                                    <div className="bold">{good.number}</div>
                                </td>
                                <td>
                                    <div>{good.supplier}</div>
                                </td>
                                {goodIdx === 0 && <>
                                    <td rowSpan={info.order_items.length}>
                                        <div className="bold">¥{info.product_amount}</div>
                                    </td>
                                    <td rowSpan={info.order_items.length}>
                                        {info.order_refund ? <>
                                            {info.order_refund.status == 1 && <div>{REMARK_TYPE_STATUS[info.order_refund.remark_type] || '买家发起退款'}</div>}
                                            {info.order_refund.status == 2 && <div>{info.actual_amount == info.order_refund.refund_amount ? '已退款' : '已部分退款'}</div>}
                                            {info.order_refund.status == 2 && <div className="item-subtl flex">已退款<span className="item-erro" style={{ marginLeft: '3px' }}>¥{info.order_refund.refund_amount}</span></div>}
                                            {info.order_refund.status == 3 && <div>退款失败</div>}
                                            {info.order_refund.status == 4 && <div>用户撤销退款</div>}
                                            <div className="item-subtl">申请退款原因：{info.order_refund.remark_type_text}</div>
                                        </> : <div>-</div>}
                                    </td>
                                    <td rowSpan={info.order_items.length}>
                                        {(info.status == 0 || isOrderCanceled(info.status)) ?
                                            <div className="item-price">¥0.00</div> : 
                                            <>
                                                <div className="item-price">¥{(info.actual_amount - info.refund_amount).toFixed(2)}</div>
                                                {Number(info.package_price) > 0 && <div className="item-subPrice">含打包费¥{+info.package_price}</div>}
                                                {Number(info.freight) > 0 && <div className="item-subPrice">含配送费¥{+info.freight}</div>}
                                            </>}
                                    </td>
                                    <td rowSpan={info.order_items.length}>
                                        <span>{timestampToString(info.estimated_time * 1000, 4)}</span>
                                    </td>
                                    <td rowSpan={info.order_items.length}>
                                        {(info.isRefund || info.status == 2 || info.status == 5) ? <div className="flex-space">
                                            {info.status == 2 && <span className="item-link" onClick={onDiningOut}>出餐</span>}
                                            {info.isRefund && <span className="item-link" onClick={onRefund}>退款</span>}
                                            {info.status == 5 && <span className="item-link disabled">退款</span>}
                                        </div> : <div>-</div>}
                                    </td>
                                </>}
                            </tr>)) : <tr>
                                <td colSpan={9}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
                <div className="flex flex-end">
                    <div className="total-box">
                        <div className="item-cell">
                            <div className="item-cell__label">商品总价：</div>
                            <div className="item-cell__number">{info.product_amount ? `¥${info.product_amount}` : '-'}</div>
                        </div>
                        <div className="item-cell">
                            <div className="item-cell__label">+打包费：</div>
                            <div className="item-cell__number">{info.package_price ? `¥${info.package_price}` : '-'}</div>
                        </div>
                        <div className="item-cell">
                            <div className="item-cell__label">+配送费：</div>
                            <div className="item-cell__number">{info.freight ? `¥${info.freight}` : '-'}</div>
                        </div>
                        {info.coupon && +info.coupon > 0 && <div className="item-cell">
                            <div className="item-cell__label">-红包券：</div>
                            <div className="item-cell__number">¥{info.coupon}</div>
                        </div>}
                        {!!info.refund_amount && Number(info.refund_amount) > 0 && <div className="item-cell">
                            <div className="item-cell__label">-退款：</div>
                            <div className="item-cell__number">¥{info.refund_amount}</div>
                        </div>}
                        <div className="item-line"></div>
                        <div className="item-cell">
                            <div className="item-cell__label">实收金额：</div>
                            {info.actual_amount
                                ? <div className="item-cell__number red">¥{(info.actual_amount - info.refund_amount).toFixed(2)}</div>
                                : <div className="item-cell__number">-</div>}
                        </div>
                    </div>
                </div>
            </div>
            <Button type="primary" style={{ marginTop: '20px' }} onClick={() => history.go(-1)}>返回</Button>

            <RefundModal ref={refundModalRef} onOk={getData} />
            <RemarkModal ref={remarkModalRef} onOk={getData} />
        </div>
    )
}

export default TakeoutSelfHelpDetail